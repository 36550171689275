import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from 'theme-ui'
import { SectionCtaBlock, BlogHeadBlock } from 'gatsby-theme-octahedroid'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Provider from '../components/provider'
import cta from '../cta'

const PageTemplate = ({ data: { mdx } }) => {
  const header = {
    ...mdx.frontmatter.header,
    image: {
      alt: mdx.frontmatter.header.image.alt,
      src: mdx.frontmatter.header.image.src.childImageSharp.fluid.src,
    },
  }

  return (
    <>
      <SEO
        {...{
          title: mdx.frontmatter.title,
          description: mdx.frontmatter.description,
          keywords: mdx.frontmatter.keywords,
          path: mdx.frontmatter.path,
          image: mdx.frontmatter.header.image.src.childImageSharp.fluid.src,
        }}
      />
      <Layout>
        <BlogHeadBlock
          title={mdx.frontmatter.title}
          date={mdx.frontmatter.date}
          {...header}
        />
        <Box __css={{ py: 'small', px: ['medium'] }}>
          <Provider>{mdx.body}</Provider>
        </Box>
        {mdx.frontmatter.cta && (
          <SectionCtaBlock {...cta[mdx.frontmatter.cta]} />
        )}
      </Layout>
    </>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string,
      frontmatter: PropTypes.shape({
        header: PropTypes.shape({
          image: PropTypes.shape({
            alt: PropTypes.string,
            src: PropTypes.shape({
              childImageSharp: PropTypes.shape({
                fluid: PropTypes.shape({
                  src: PropTypes.string,
                }),
              }),
            }),
          }),
        }),
        date: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
        keywords: PropTypes.arrayOf(PropTypes.string),
        cta: PropTypes.string,
        path: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default PageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        source
      }
      body
      frontmatter {
        title
        description
        keywords
        cta
        path
        date(formatString: "MMMM DD, YYYY")
        header {
          credits
          author {
            name
            link
            ariaLabel
          }
          image {
            src {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`
